<template>
  <div class="query-builder">
    <div class="heading">
      <h2>{{client}}</h2>
      <h1>{{location}}</h1>
    </div>

    <div class="reboundary-card">
<!--      <gmap-map :center="map_location" :zoom="12"-->
<!--                style="width:100%;  height: 400pt;"></gmap-map>-->
      <query-map :bounds="mapBounds"></query-map>
    </div>

    <div class="flex-row-space-between">
      <div class="reboundary-card flex-column card-grow-2">
        <div class="card-heading">REPORT NAME<span class="red-note">*</span></div>
        <b-form-input type="text" rows="4" v-validate="'required'" name="report-name" v-model="reportName"
                      placeholder="Enter Report Name"></b-form-input>
        <div
            v-if="errors.has('report-name')"
            class="alert alert-danger"
            role="alert"
        >
          You must provide a Report Name.
        </div>
        <div class="card-heading">PROBLEM STATEMENT</div>
        <b-form-textarea type="text-area" rows="4" v-validate="'required'" name="problem" v-model="problemText"></b-form-textarea>
        <div
            v-if="errors.has('problem')"
            class="alert alert-danger"
            role="alert"
        >
          You must provide a Problem Statement.
        </div>
      </div>
      <div class="reboundary-card card-grow-1 query-image">
      </div>
    </div>

    <div class="reboundary-card">
      <div class="card-heading">
        SELECT CATEGORY
      </div>
      <div class="card-rule"></div>
      <div class="data-set-container">
        <Loading :is_loading="loading" class="loading" message="Loading Categories..."/>
        <div class="data-set-container" v-if="!loading">
          <div v-for="(category, idx) of categories" v-bind:key="idx" @click="toggleCategory(category)"
               class="rebutton-icon" :class="{'selected': isCategorySelected(category)}">
            <div class="icon" :class="{'selected': isCategorySelected(category)}">
              <font-awesome-icon icon="database" size="1x" />
            </div>
            {{category.text}}
          </div>
        </div>
      </div>
      <div
          v-if="validated && selectedCategories.length === 0"
          class="alert alert-danger"
          role="alert"
      >
        One or more Categories must be selected.
      </div>
      <div class="card-rule"></div>
      <div class="flex-row-space-between">
        <div class="card-item p-2">
          <div class="card-subheading">Select Date:</div>
          <div class="flex-grow-1">
            <b-input-group>
              <b-form-datepicker v-model="date"></b-form-datepicker>
              <b-input-group-append>
                <b-button @click="date = new Date().toISOString().slice(0, 10)">Now</b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>
        <div class="card-rule-horizontal"></div>
        <div class="card-item p-2">
          <div class="card-subheading">Select Granularity:</div>
          <div class="flex-col-centered align-content-center">
            <b-form-radio-group v-model="granularity" :options="granularityOptions" name="granularity" buttons v-validate="'required'">
            </b-form-radio-group>
            <div class="text-center">
              Clusters: {{granularity}} - {{granularityText[granularity]}}
            </div>
          </div>
<!--          <b-form-input type="range" min="3" max="7" step="2" v-model="granularity"></b-form-input>-->

        </div>

      </div>
      <div class="card-rule"></div>
      <b-alert variant="danger" v-if="this.errorMessage" show>
        <h6>An Error Occurred. Please try again.</h6>
        <p>{{errorMessage}}</p>
      </b-alert>
      <Loading :is_loading="loadingReport" class="loading" message="Creating Reboundary Request..."/>
      <div class="d-flex flex-row-reverse">
        <b-button class="rebutton execute-button " @click="submitQuery" :disabled="loadingReport">Create Report</b-button>
      </div>

    </div>
  </div>
</template>

<script>

import Loading from "./Loading";
import DashboardService from '@/services/dashboard.service';
import QueryMap from "./QueryMap";

export default {
  name: 'QueryBuilder',
  components: {
    Loading,
    QueryMap
  },
  data() {
    return {
      loading: true,
      loadingReport: false,
      location: null,
      client: null,
      map_location: { lat: -38.3687, lng: 142.4982 },
      datasets: [
      ],
      selectedCategories: [],
      granularityOptions: [
        {
          text: 'Low',
          value: 3
        },
        {
          text: 'Medium',
          value: 5
        },
        {
          text: 'High',
          value: 7
        }
      ],
      granularity: 3,
      granularityText: {
        3: 'Low',
        5: 'Medium',
        7: 'High'
      },
      date: new Date(),
      problemText: 'Enter your problem statement',
      reportName: null,
      validated: false,
      errorMessage: null
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    mapBounds() {
      if (this.$store.state.query.selected_location) {
        let location = this.$store.state.query.locations.find(x => x.name === this.$store.state.query.selected_location)
        return location.bounding_box
      }
      return null
    }
  },
  async mounted() {
   if (!this.$store.state.query.selected_location) {
     this.$router.push('/home/locations');
   }
   console.log('Locations: ', this.$store.state.query.selected_location)
    this.location = this.$store.state.query.selected_location
    // let locationData = this.$store.state.query.locations.find(x => x.name === this.location)
    // this.mapBounds = locationData.bounding_box
    console.log('User: ', this.$store.state.auth.user)
    this.client = this.$store.state.auth.user.organisation

    if (!this.$store.state.query.categories) {
      await  this.$store.dispatch('query/getCategories')
    }
    this.categories = this.$store.state.query.categories.map((x) => { return {
      value: x.name,
      text: x.name,
      icon: this.getIcon(x.icon_name) }
    })

    if (this.$store.state.query.selected_categories) {
      this.selectedCategories = this.$store.state.query.selected_categories
    }

    console.log('Categories: ', this.categories)
    this.loading = false
  },
  methods: {
    async submitQuery() {
      this.loadingReport = true;

      this.$validator.validateAll().then((isValid) => {
        console.log(this.errors)
        this.validated = true
        if (!isValid) {
          this.loadingReport = false;
          return;
        }

        let categories = this.selectedCategories.map(x => x.value)
        DashboardService.reportStart(
            this.reportName, this.location,this.date, categories, this.granularity).then(
            result => {
              console.log(result.data)
              if (result.data.status === 'Complete') {
                this.$router.push('/home/reports/' + result.data.report_id)
              } else {
                this.$router.push('/home/jobs/' + result.data.report_id)
              }
            }
        ).catch(error => {
          console.log(error)
          this.errorMessage = error
        })
        this.loadingReport = false
      });
    },
    toggleCategory(cat) {
      if (this.isCategorySelected(cat)) {
        this.selectedCategories = this.selectedCategories.filter(x => x !== cat)
      } else {
        this.selectedCategories.push(cat)
      }
    },
    isCategorySelected(cat) {
      return this.selectedCategories.includes(cat)
    },
    getIcon(iconName) {
      if (iconName) {
        return 'iconName'
      } else {
        return 'database'
      }
    }
  },
};
</script>

<style scoped>

.query-builder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 2em 4em;
}

.reboundary-card {
  margin: 1%;
}

.heading {

}

.card-grow-2 {
  flex-grow: 2;
}

.card-grow-1 {
  flex-grow: 1;
}

.card-item {
  display: flex;
  flex-direction: column;
  justify-items: stretch;
  flex-grow: 1;
}

.card-heading {
  font-size: 1.8em;
  font-weight: 400;
  justify-self: start;
}

.card-subheading {
  font-size: 1.2em;
  font-weight: 400;
  color: #023856;
  margin-bottom: 1em;
  justify-self: start;
}

.flex-col-centered {
  align-items: stretch;
}

.card-rule {
  /*border: 1px solid black;*/
  background: black;
  height: 1px;
  margin: 15px 5px;
}

.card-rule-horizontal {
  /*border: 1px solid black;*/
  background: black;
  width: 1px;
  margin: 5px 2em;
}

.data-set-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.execute-button {
  background: #19b480;
}

.execute-button:hover {
  background: #0f6c4d;
}

/* Negative Marign for the Granularity Button Group to line up with the date picker*/
.btn-group {
  margin-top: -10px;
}

.query-image {
  aspect-ratio: 1.5;
  background: url('../assets/img/city.jpg');
  background-size: cover;
}

.alert h6 {
  color: #b71e00;
}

.red-note {
  color: red;
  font-size: 1em;
}

</style>
