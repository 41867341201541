<template>
  <div class="map">
    <gmap-map
        :center="{lat: -27.4061, lng: 135.97438}"
      :zoom="4"
      style="width:100%;  height: 100%;"
      v-bind:options="mapStyle"
    ref="mapRef">
    </gmap-map>
    <!-- const colour = get_colour(current_square.label);
    const square = new google.maps.Polygon({
        map,
        fillColor: colour,
        fillOpacity: 1,
        strokeOpacity: 0.0,
        paths: current_square.path,
        indexID: current_square.label,
        number: rectangles.length,
    });
    rectangles.push(square);
    google.maps.event.addListener(square, 'click', () => {
        on_square_click(square);
    }); -->
  </div>
</template>

<script>
import colours from '@/translation/cluster_colour_codes.js';

export default {
  name: 'QueryMap',
  props: ['bounds'],
  data() {
    return {
      mapStyle: {
        styles: [
          {
            elementType: 'geometry',
            stylers: [{ saturation: -100 }]
          }
        ]
      },
    };
  },

  computed: {
  },

  mounted() {
    if (this.bounds) {
      this.updateBounds(this.bounds)
    }
  },

  methods: {
    async updateBounds(locationBoundaryData) {
      console.log('Update Bounds: ', locationBoundaryData)
      let map = await this.$refs.mapRef.$mapPromise
      let bounds = {
        east: locationBoundaryData['max_lon'],
        north: locationBoundaryData['max_lat'],
        south: locationBoundaryData['min_lat'],
        west: locationBoundaryData['min_lon']
      }
      console.log(bounds)
      map.fitBounds(bounds)
    }
  },
  watch: {
    async bounds(newBounds) {
      console.log('Bounds Change!', this.bounds)
     this.updateBounds(newBounds)
    }
  }

}
</script>
<style scoped>
.map {
  background-color: lightgrey;
  width: 100%;
  height: 400pt;
}
</style>
